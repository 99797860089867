import router from "@/router";
import store from "@/store";
import { Auth } from "aws-amplify";
import notification from "./notificationService";

const signOut = async (params: Record<string, any>) => {
  try {
    await Auth.signOut();
    store.dispatch("resetStore");
    store.dispatch("setIsLoggedIn", false);
    store.dispatch("setCurrentUser", undefined);
    // if live location timeout exist clear it
    store.dispatch("clearLiveLocationTimeout");
    router.push({
      name: "LogIn",
      query: {
        ...params
      }
    });
  } catch (err) {
    notification.error(err.message);
  }
};

const validation = {
  hasValidCoordinates: (value: string) => {
    const coordinates = value.split(",").map(function(item: string) {
      return item.trim();
    });
    if (coordinates.length === 2) {
      if (
        coordinates[0].match(
          new RegExp(/^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/)
        ) &&
        coordinates[1].match(
          new RegExp(
            /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/
          )
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  hasSpecialCharacters: (value: string) => {
    if (value.match(new RegExp(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g))) {
      return true;
    } else {
      return false;
    }
  },
  hasLowerCase: (value: string) => {
    if (value.match(new RegExp("[a-z]"))) {
      return true;
    } else {
      return false;
    }
  },
  hasUpperCase: (value: string) => {
    if (value.match(new RegExp("[A-Z]"))) {
      return true;
    } else {
      return false;
    }
  },
  hasNumber: (value: string) => {
    if (value.match(new RegExp("[0-9]"))) {
      return true;
    } else {
      return false;
    }
  }
};

const helpers = {
  extractCoordinates: (value: string) => {
    if (validation.hasValidCoordinates(value)) {
      // split on , and remove spaces using trim
      const coordinates = value.split(",").map(function(item: string) {
        return item.trim();
      });
      // return coordinates as object
      return {
        lat: parseFloat(coordinates[0]),
        lng: parseFloat(coordinates[1])
      };
    } else {
      // if no valid coordinates found return undefined
      return undefined;
    }
  },
  fetchBehaviorIcon(behaviorId: number) {
    if (behaviorId === 10) {
      return {
        title: "Harsh braking",
        icon: "driving_behaviour/harsh_braking.svg",
        scaledSize: 40
      };
    } else if (behaviorId === 11) {
      return {
        title: "Acceleration",
        icon: "driving_behaviour/acceleration.svg",
        scaledSize: 40
      };
    } else if (behaviorId === 12) {
      return {
        title: "Harsh left",
        icon: "driving_behaviour/turn_left.svg",
        scaledSize: 40
      };
    } else if (behaviorId === 13) {
      return {
        title: "Harsh right",
        icon: "driving_behaviour/turn_right.svg",
        scaledSize: 40
      };
    } else {
      return {
        icon: "map_marker.svg",
        scaledSize: 20
      };
    }
  },
  carryForwardEvents(events: Array<any>) {
    // Preprocessing raw events to carry forward coordinates in events
    let lastKnownLatitude = null;
    let lastKnownLongitude = null;
    // finding first valid event
    for (let i = 0; i < events.length; i++) {
      // check if valid
      if (events[i].LATITUDE !== null && events[i].LONGITUDE !== null) {
        //store coordinates
        lastKnownLatitude = events[i].LATITUDE;
        lastKnownLongitude = events[i].LONGITUDE;
        // break the loop
        break;
      }
    }
    for (let i = 0; i < events.length; i++) {
      if (events[i].LATITUDE === null) {
        events[i].LATITUDE = lastKnownLatitude;
      } else if (events[i].LATITUDE) {
        lastKnownLatitude = events[i].LATITUDE;
      }
      if (events[i].LONGITUDE === null) {
        events[i].LONGITUDE = lastKnownLongitude;
      } else if (events[i].LONGITUDE) {
        lastKnownLongitude = events[i].LONGITUDE;
      }
    }
    return events;
  }
};

export { signOut, validation, helpers };
