
import Vue from "vue";
import { filter } from "lodash";
import gmapsInit from "@/services/mapService";
import { validation, helpers } from "@/services/common";
import { GeoFenceType } from "@/shared/models";
import notification from "@/services/notificationService";

export default Vue.extend({
  name: "GeoFenceMap",
  data() {
    return {
      lodashFilter: filter,
      GeoFenceType,
      validation,
      polygonOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35
      },
      map: undefined as undefined | any,
      drawingManager: undefined as undefined | any,
      geoFenceType: GeoFenceType.Polygon as GeoFenceType,
      placesSearchQuery: "",
      cooridnatesQuery: "",
      selectedSearchType: "address",
      filters: {
        vehicle: undefined as undefined | number,
        vehicleSearchQuery: ""
      },
      geoFence: {
        name: undefined as undefined | string,
        description: undefined as undefined | string,
        alertLeavingArea: false,
        alertEnteringArea: false,
        isCreated: false,
        vehicles: [] as string[],
        regionCirlce: undefined as any,
        circleMarker: undefined as any,
        polygonRegion: undefined as any,
        polygonGeoJson: undefined as any,
        radius: 0
      },
      showRadiusErrorMessage: false,
      addressSearchMarker: undefined as any,
      filteredVehicles: undefined as undefined | any
    };
  },
  computed: {
    vehicles() {
      return this.$data.lodashFilter(this.$store.state.vehicles, {
        dongleActive: true
      });
    },
    geoFences() {
      return this.$store.state.geoFences;
    },
    selectedGeoFence() {
      return this.$store.state.selectedGeoFence;
    },
    radius() {
      if (this.$data.geoFence.regionCirlce) {
        return this.$data.geoFence.regionCirlce.getRadius();
      } else {
        return 0;
      }
    },
    defaultTenantLocationSettings() {
      return this.$store.state.defaultTenantLocationSettings;
    },
    editMode() {
      return this.$route.query.id ? true : false;
    }
  },
  watch: {
    geoFenceType: function(newValue: string) {
      if (newValue === GeoFenceType.Polygon) {
        this.drawingManager.setMap(this.map);
      } else if (newValue === GeoFenceType.Radius) {
        this.drawingManager.setMap(null);
      }
    },
    radius: function(newValue: number) {
      if (newValue > 50) {
        this.geoFence.radius = parseFloat(newValue.toFixed(3));
        // round to nearest 50m
        this.geoFence.regionCirlce.setRadius(newValue);
        // remove radius error message
        this.showRadiusErrorMessage = false;
      } else if (newValue < 50) {
        this.geoFence.radius = 50;
        this.geoFence.regionCirlce.setRadius(50);
        // display radius error message
        this.showRadiusErrorMessage = true;
      }
    },
    cooridnatesQuery: async function(newCoordinates) {
      if (this.geoFence.isCreated && this.placesSearchQuery === "") {
        // check if a previously created geoFence exist
        // this.placesSearchQuery === "" is checked to
        // exclude geoFence created via address search
        this.discardGeoFence();
      }
      if (validation.hasValidCoordinates(newCoordinates)) {
        if (this.geoFenceType === GeoFenceType.Radius) {
          // draw geofence on map
          this.createCircle(helpers.extractCoordinates(newCoordinates), 1000);
          this.fitGeoFence();
          this.geoFence.isCreated = true;
        } else if (this.geoFenceType === GeoFenceType.Polygon) {
          const google: any = await gmapsInit();
          this.map.setCenter(helpers.extractCoordinates(newCoordinates));
          this.map.setZoom(16);
          this.addressSearchMarker = new google.maps.Marker({
            position: helpers.extractCoordinates(newCoordinates),
            map: this.map,
            icon: {
              scaledSize: new google.maps.Size(40, 40)
            }
          });
        }
      }
    },
    selectedGeoFence: async function(newData) {
      this.loadGeoFence(newData);
    },
    vehicles: function(vehicles) {
      if (vehicles.length) {
        this.filterVehicleList();
      }
    }
  },
  async created() {
    await this.getVehicles();
    if (this.$route.query.id) {
      await this.$store.dispatch("getSelectedGeoFence", this.$route.query.id);
    } else {
      // get default location from user settings
      await this.$store.dispatch("getDefaultTenantLocationSettings");
      // if no geofence is selected for editing, show default location as center
      // if default location cannot be fetched show Munich
      await this.loadMap(
        {
          lat: this.defaultTenantLocationSettings.latitude || 48.1351,
          lng: this.defaultTenantLocationSettings.longitude || 11.582
        },
        12
      );
    }
  },
  methods: {
    filterVehicleList() {
      if (this.vehicles) {
        this.filteredVehicles = this.vehicles;
        if (this.filters.vehicleSearchQuery !== "") {
          this.filteredVehicles = this.filteredVehicles?.filter(
            (vehicles: any) => {
              return (
                vehicles.registrationNo
                  ?.toLowerCase()
                  .includes(this.filters.vehicleSearchQuery!.toLowerCase()) ||
                vehicles.nickname
                  ?.toLowerCase()
                  .includes(this.filters.vehicleSearchQuery!.toLowerCase()) ||
                vehicles.make
                  ?.toLowerCase()
                  .includes(this.filters.vehicleSearchQuery!.toLowerCase()) ||
                vehicles.model
                  ?.toLowerCase()
                  .includes(this.filters.vehicleSearchQuery!.toLowerCase())
              );
            }
          );
        }
      }
    },
    async createCircle(center: any, radius: number) {
      if (this.selectedSearchType === "coordinates") {
        this.placesSearchQuery = "";
      } else if (this.selectedSearchType === "address") {
        this.cooridnatesQuery = "";
      }
      // function initializes a circle on this.geoFence.regionCirlce and a corresponding marker
      // on this.geoFence.circleMarker as center on the map

      // If a circle already exist unset circle and marker
      if (this.geoFence.regionCirlce) {
        this.geoFence.regionCirlce?.setMap(null);
        this.geoFence.circleMarker?.setMap(null);
      }
      const google: any = await gmapsInit();
      this.geoFence.regionCirlce = new google.maps.Circle({
        ...this.polygonOptions,
        map: this.map,
        center: center,
        radius: radius,
        editable: true
      });
      this.geoFence.regionCirlce.addListener("radius_changed", () => {
        this.fitGeoFence();
      });
      // create marker in center
      this.geoFence.circleMarker = new google.maps.Marker({
        position: this.geoFence.regionCirlce.getCenter(),
        map: this.map,
        draggable: true,
        icon: {
          url: require("@/assets/img/map_marker_draggable.svg"),
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(20, 20)
        }
      });
      // add event listener to trigger fitGeoFence when the marker is dragged
      this.geoFence.circleMarker.addListener("dragend", () => {
        this.fitGeoFence();
      });
      // bind circle's center to marker
      this.geoFence.regionCirlce.bindTo(
        "center",
        this.geoFence.circleMarker,
        "position"
      );
    },
    async getVehicles() {
      await this.$store.dispatch("getVehicles", { archived: false });
    },
    setRadius() {
      if (this.geoFence.radius == 0) {
        this.geoFence.regionCirlce.setRadius(50);
      } else if (this.geoFence.regionCirlce && this.geoFence.radius > 0) {
        this.geoFence.regionCirlce.setRadius(
          parseFloat(this.geoFence.radius.toString())
        );
      }
    },
    async loadGeoFence(geoFence: Record<string, any>) {
      const google: any = await gmapsInit();
      if (geoFence) {
        // load map based on the selected geofence for editing
        await this.loadMap(
          {
            lat: geoFence.detail.sourceLatitude,
            lng: geoFence.detail.sourceLongitude
          },
          16
        );
        this.geoFence.name = geoFence.name;
        this.geoFence.description = geoFence.description;
        this.geoFence.alertLeavingArea = geoFence.alertLeavingArea;
        this.geoFence.alertEnteringArea = geoFence.alertEnteringArea;
        if (geoFence.type === GeoFenceType.Polygon) {
          this.geoFenceType = GeoFenceType.Polygon;
          // polygon path extracted from stored geoJSON
          const polygonPath = geoFence.detail.coordinates[0].map(
            (coordinate: number[]) => {
              return { lat: coordinate[1], lng: coordinate[0] };
            }
          );
          // Add polygon on map
          this.geoFence.polygonRegion = new google.maps.Polygon({
            ...this.polygonOptions,
            paths: polygonPath,
            map: this.map,
            editable: true
          });
          // Setting map to display full polygon via fitBounds()
          const latlngbounds = new google.maps.LatLngBounds();
          for (let i = 0; i < polygonPath.length; i++) {
            latlngbounds.extend(
              new google.maps.LatLng(polygonPath[i].lat, polygonPath[i].lng)
            );
          }
          this.map.fitBounds(latlngbounds, { left: 350 });
        } else if (geoFence.type === GeoFenceType.Radius) {
          this.geoFenceType = GeoFenceType.Radius;
          this.geoFence.radius = geoFence.detail.radius;
          await this.createCircle(
            new google.maps.LatLng(
              geoFence.detail.sourceLatitude,
              geoFence.detail.sourceLongitude
            ),
            geoFence.detail.radius
          );
          this.fitGeoFence();
        }
        for (let i = 0; i < geoFence.vehicles.length; i++) {
          this.geoFence.vehicles.push(geoFence.vehicles[i].id);
        }
        this.geoFence.isCreated = true;
        this.hideDrawingManager();
      }
    },
    submitGeoFence() {
      if (this.$route.query.id) {
        // check if the form contains a geofence to be updated
        this.updateGeoFence();
      } else {
        // create new geofence
        this.addGeoFence();
      }
    },
    addGeoFence() {
      const request = {
        name: this.geoFence.name,
        description: this.geoFence.description,
        alertLeavingArea: this.geoFence.alertLeavingArea,
        alertEnteringArea: this.geoFence.alertEnteringArea,
        detail: {},
        vehicles: this.geoFence.vehicles.map(vehicle => {
          return {
            id: vehicle
          };
        }),
        type: ""
      };
      // adding geofence data based on type
      if (this.geoFenceType === GeoFenceType.Polygon) {
        request.detail = {
          coordinates: this.geoFence.polygonGeoJson
        };
        request.type = GeoFenceType.Polygon;
      } else if (this.geoFenceType === GeoFenceType.Radius) {
        request.detail = {
          sourceLatitude: this.geoFence.regionCirlce.getCenter().lat(),
          sourceLongitude: this.geoFence.regionCirlce.getCenter().lng(),
          radius: this.geoFence.regionCirlce.getRadius()
        };
        request.type = GeoFenceType.Radius;
      }
      this.$store.dispatch("addGeoFence", request);
      this.$store.dispatch("getGeoFences");
      this.$router.push({ name: "GeoFence" });
    },
    updateGeoFence() {
      const request = {
        name: this.geoFence.name,
        description: this.geoFence.description,
        detail: {},
        alertLeavingArea: this.geoFence.alertLeavingArea,
        alertEnteringArea: this.geoFence.alertEnteringArea,
        vehicles: this.geoFence.vehicles.map(vehicle => {
          return {
            id: vehicle
          };
        }),
        type: ""
      };
      // adding geofence data based on type
      if (this.geoFenceType === GeoFenceType.Polygon) {
        const vertices = this.geoFence.polygonRegion.getPath();
        this.geoFence.polygonGeoJson = [];
        // populating polygon geoJSON from editable polygon
        for (let i = 0; i < vertices.getLength(); i++) {
          const coordinates = vertices.getAt(i);
          this.geoFence.polygonGeoJson.push([
            coordinates.lng(),
            coordinates.lat()
          ]);
        }

        // checking if first and last point of drawn polygon is the same
        // to retain complete bounding box, drawing manager fails to
        // maintain this if a new point is added during editing the polygon
        if (
          JSON.stringify(this.geoFence.polygonGeoJson[0]) !==
          JSON.stringify(
            this.geoFence.polygonGeoJson[
              this.geoFence.polygonGeoJson.length - 1
            ]
          )
        ) {
          // if the first and last points are not the same, append first
          // point to the array to complete the bounding box
          this.geoFence.polygonGeoJson.push(this.geoFence.polygonGeoJson[0]);
        }

        request.detail = {
          coordinates: [this.geoFence.polygonGeoJson]
        };
        request.type = GeoFenceType.Polygon;
      } else if (this.geoFenceType === GeoFenceType.Radius) {
        request.detail = {
          sourceLatitude: this.geoFence.regionCirlce.getCenter().lat(),
          sourceLongitude: this.geoFence.regionCirlce.getCenter().lng(),
          radius: this.geoFence.regionCirlce.getRadius()
        };
        request.type = GeoFenceType.Radius;
      }
      const geoFenceId = this.$route.query.id.toString();
      this.$store.dispatch("updateGeoFence", {
        id: geoFenceId,
        geoFence: request
      });
      this.$router.push({ name: "GeoFence", query: { id: geoFenceId } });
    },
    async loadMap(center: { lat: number; lng: number }, zoom: number) {
      try {
        const google: any = await gmapsInit();
        const geocoder = new google.maps.Geocoder();
        this.map = new google.maps.Map(
          document.getElementById("map") as HTMLElement,
          {
            center: center,
            zoom: zoom,
            mapTypeControl: false
          }
        );
        // Add Drawing manager
        this.drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.POLYGON,
              google.maps.drawing.OverlayType.RECTANGLE
            ]
          },
          polygonOptions: {
            ...this.polygonOptions,
            clickable: false,
            editable: true,
            zIndex: 1
          },
          rectangleOptions: {
            ...this.polygonOptions,
            clickable: false,
            editable: true,
            zIndex: 1
          }
        });
        this.drawingManager.setMap(this.map);
        // create data layer
        const dataLayer = new google.maps.Data();
        // listen to overlay complete event
        google.maps.event.addListener(
          this.drawingManager,
          "overlaycomplete",
          (event: any) => {
            if (!this.geoFence.polygonRegion) {
              // store event overlay
              this.geoFence.polygonRegion = event.overlay;
              switch (event.type) {
                case google.maps.drawing.OverlayType.RECTANGLE: {
                  const b = event.overlay.getBounds();
                  const p = [
                    b.getSouthWest(),
                    {
                      lat: b.getSouthWest().lat(),
                      lng: b.getNorthEast().lng()
                    },
                    b.getNorthEast(),
                    {
                      lng: b.getSouthWest().lng(),
                      lat: b.getNorthEast().lat()
                    }
                  ];
                  dataLayer.add(
                    new google.maps.Data.Feature({
                      geometry: new google.maps.Data.Polygon([p])
                    })
                  );
                  break;
                }
                case google.maps.drawing.OverlayType.POLYGON:
                  dataLayer.add(
                    new google.maps.Data.Feature({
                      geometry: new google.maps.Data.Polygon([
                        event.overlay.getPath().getArray()
                      ])
                    })
                  );
                  break;
              }
              // save geoJSON to polygon geoJSON
              dataLayer.toGeoJson((obj: any) => {
                // dataLayer can hold multiple features
                // since drawing is truncated on completion of first one
                // geometry of drawn polygon is at features[0]
                this.geoFence.polygonGeoJson =
                  obj.features[0].geometry.coordinates;
              });
              // hide drawing options
              this.drawingManager.setMap(null);
              // set geoFence created to true
              this.geoFence.isCreated = true;
            }
          }
        );
        // Add option to draw circle on map
        this.map.addListener("click", async (event: { latLng: any }) => {
          if (!this.geoFence.isCreated) {
            await this.createCircle(event.latLng, 1000);
            this.fitGeoFence();
            this.geoFence.isCreated = true;
          }
        });
        this.initPlacesSearch();
      } catch (err) {
        notification.error(err.message);
      }
    },
    async initPlacesSearch() {
      try {
        const google: any = await gmapsInit();

        // Create the search box and link it to the UI element.
        const input = document.getElementById(
          "places-search"
        ) as HTMLInputElement;
        const searchBox = new google.maps.places.SearchBox(input);

        // Bias the SearchBox results towards current map's viewport.
        this.map.addListener("bounds_changed", () => {
          searchBox.setBounds(this.map.getBounds());
        });

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
          const places = searchBox.getPlaces();

          if (places.length == 0) {
            return;
          }

          places.forEach(
            (place: {
              geometry: { location: any; viewport: any };
              icon: string;
              name: string;
              formatted_address: string;
            }) => {
              if (!place.geometry || !place.geometry.location) {
                // Returned place contains no geometry
                return;
              } else if (this.geoFenceType === GeoFenceType.Radius) {
                // if geofenceType is radius draw a cricle on searched address

                // if previously searched address exist remove it
                if (this.addressSearchMarker) {
                  this.addressSearchMarker.setMap(null);
                }
                this.createCircle(place.geometry.location, 1000);
                this.fitGeoFence();
                this.geoFence.isCreated = true;
              } else if (this.geoFenceType === GeoFenceType.Polygon) {
                // if geofenceType is polygon move the map on searched address

                // if previously searched address exist remove it
                if (this.addressSearchMarker) {
                  this.addressSearchMarker.setMap(null);
                }
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(15);
                this.addressSearchMarker = new google.maps.Marker({
                  position: place.geometry.location,
                  map: this.map,
                  icon: {
                    scaledSize: new google.maps.Size(40, 40)
                  }
                });
              }
            }
          );
        });
      } catch (err) {
        notification.error(err.message);
      }
    },
    discardGeoFence() {
      if (this.geoFenceType === GeoFenceType.Radius) {
        this.geoFence.regionCirlce.setMap(null);
        this.geoFence.circleMarker.setMap(null);
      } else if (this.geoFenceType === GeoFenceType.Polygon) {
        // hide drawn polygon from map
        this.geoFence.polygonRegion.setMap(null);
        // remove stored polygon
        this.geoFence.polygonRegion = undefined;
        // clear stored geo json
        this.geoFence.polygonGeoJson = undefined;
        // enable drawing tools on map
        this.drawingManager.setMap(this.map);
      }
      if (this.addressSearchMarker) {
        this.addressSearchMarker.setMap(null);
      }
      this.geoFence.isCreated = false;
      this.geoFence.vehicles = [];
      this.placesSearchQuery = "";
      if (this.geoFence.regionCirlce) {
        this.geoFence.regionCirlce.setMap(null);
      }
      if (this.geoFence.circleMarker) {
        this.geoFence.circleMarker.setMap(null);
      }
      this.showRadiusErrorMessage = false;
      // re initialize places search (google autocomplete)
      this.initPlacesSearch();
    },
    async fitGeoFence() {
      const google: any = await gmapsInit();
      const bounds = new google.maps.LatLngBounds();
      bounds.union(this.geoFence.regionCirlce.getBounds());
      // padding left: 20% (window.innerWidth * 0.2) to prevent the
      // circle from going under the form overlay
      this.map.fitBounds(bounds, { left: window.innerWidth * 0.2 });
    },
    hideDrawingManager() {
      if (this.drawingManager) {
        this.drawingManager.setMap(null);
      }
    }
  }
});
